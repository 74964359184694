import captureException from '../../utils/sentry';
import { parseJsonDeprecated } from '../../shared/api/utils';
import { REGISTERED_APPS } from '../../shared/types/Apps';

const reportChunkError = async (app: REGISTERED_APPS, shop: string) => {
  try {
    return await parseJsonDeprecated(
      fetch(
        `${process.env.REACT_APP_MONITOR_CHUNKS_API_HOSTNAME}api/chunk-error/`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({ app_id: app, shop }),
        },
      ),
    );
  } catch (e) {
    captureException(e);
  }
};

export default reportChunkError;
