const getLanguage = (): string => {
  const apps = {
    Weglot: () => window.Weglot?.getCurrentLang(),
    langify: () => window.langify?.locale?.iso_code,
    GTranslateGetCurrentLang: () => window.GTranslateGetCurrentLang(),
    LangShop: () =>
      window.LangShop?.currentLanguage ||
      window.LangShop?.getCurrentLanguage()?.code,
  };

  for (const [app, cb] of Object.entries(apps)) {
    // @ts-ignore
    if (window[app]) {
      return cb() || window.Shopify.locale;
    }
  }

  // Most apps depend on Shopify locale
  return window.Shopify?.locale;
};

export default getLanguage;
