import { logError } from '../shared/utils/logging';
import { getCart } from '../shared/api/shared-app-requests';

function affixScriptToHead(url: string) {
  return new Promise((resolve, reject) => {
    var newScript = document.createElement('script');
    newScript.onerror = (oError) => {
      reject(
        new URIError(
          //@ts-ignore
          oError instanceof Event
            ? 'The script ' +
              (oError.target as HTMLScriptElement).src +
              " didn't load correctly."
            : "The script didn't load correctly. " + oError,
        ),
      );
    };
    newScript.onload = resolve;
    document.head.appendChild(newScript);
    newScript.src = url;
  });
}

export async function fillWindowWithCurrencyFunction() {
  const token = await getCart();

  if (token && window.Shopify.checkout) {
    try {
      if (!window.Currency) {
        await affixScriptToHead(
          'https://cdn.shopify.com/s/javascripts/currencies.js',
        );
      }
    } catch (error) {
      logError(error);
    }
  }
}
