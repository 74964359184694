export type AppInfo = {
  is_installed: boolean;
};

export type AppInfoCandyRack = AppInfo & {
  config: null | string;
  is_checkout_button_offers_existing: boolean;
  is_thank_you_page_offers_existing: boolean;
  storefront_access_token: string;
};

export type AppsInfo = {
  candycart: AppInfo;
  candyrack: AppInfoCandyRack;
  last_upsell: AppInfo;
  error_code?: string;
};

export enum AppsPageType {
  CANDY_RACK_PRODUCTS = 'products',
  CANDY_RACK_COLLECTIONS = 'collections',
  LAST_UPSELL = 'checkouts',
}
