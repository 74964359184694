import storage from '../shared/utils/storage';
import {
  CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY,
  CANDYRACK_CHECKOUT_BUTTON_DISCOUNT_CODE_LOCAL_STORAGE_KEY,
  CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
  CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY,
  CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY,
  IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES,
} from '../shared/constants/app';
import { AppsInfo as AppsInfoAPIResponse, AppsPageType } from '../types';
import {
  canLoadOnUrl,
  isCheckoutPage,
  isRelevantPageForTracking,
} from './page-utils';
import { getCheckoutButtonSelectors } from '../dom/domUtils';
import { isMultipleDiscountsExist } from '../shared/utils/discounts';
import { REGISTERED_APPS } from '../shared/types/Apps';
import { logInfo } from '../shared/utils/logging';

export type CandyRackPopupLoadResult = {
  isEnabled: boolean;
  shouldRender: boolean;
};

export function clearCandyrackCheckoutButtonDiscountInfoFromStorage() {
  if (appFunctions.isBaseApp()) {
    storage.removeItem(
      CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
    );
    storage.removeItem(
      CANDYRACK_CHECKOUT_BUTTON_DISCOUNT_CODE_LOCAL_STORAGE_KEY,
    );
  }
}

/*
 ** Wrapping these functions inside appFunctions, so we can spy on them in tests
 */
export const appFunctions = {
  isBaseApp: () => {
    if (storage.getItem(IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES) === 'true') {
      return true;
    }
    if (storage.getItem(IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES) === 'false') {
      return false;
    }
    return Boolean(
      process.env.REACT_APP_CUSTOMER_API_HOSTNAME !== 'undefined' &&
        process.env.REACT_APP_CUSTOMER_API_HOSTNAME &&
        process.env.REACT_APP_CUSTOMER_API_HOSTNAME.trim().length > 0,
    );
  },
  isForkedApp: () => {
    if (storage.getItem(IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES) === 'true') {
      return false;
    }
    if (storage.getItem(IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES) === 'false') {
      return true;
    }
    return Boolean(
      storage.getItem(IS_CANDYRACK_BASE_APP_FOR_TEST_PURPOSES) === 'false' ||
        (process.env.REACT_APP_LAST_UPSELL_API_HOSTNAME !== 'undefined' &&
          process.env.REACT_APP_LAST_UPSELL_API_HOSTNAME &&
          process.env.REACT_APP_LAST_UPSELL_API_HOSTNAME.trim().length > 0),
    );
  },
  isCandyRackExistingAndNotLoading: (res: AppsInfoAPIResponse) =>
    res.candyrack?.is_installed && !window.CandyRack?.isLoading,
};

export function shouldAddCurrencyFunction(
  res: AppsInfoAPIResponse,
  crLoadRes: CandyRackPopupLoadResult,
): boolean {
  return (
    appFunctions.isCandyRackExistingAndNotLoading(res) &&
    isRelevantPageForTracking(crLoadRes)
  );
}

export function shouldCleanupCandyRackForTrackingRelevantPage(
  res: AppsInfoAPIResponse,
  crLoadRes: CandyRackPopupLoadResult,
): boolean {
  return (
    appFunctions.isBaseApp() &&
    appFunctions.isCandyRackExistingAndNotLoading(res) &&
    isRelevantPageForTracking(crLoadRes)
  );
}

export function shouldLoadCandyRack(
  res: AppsInfoAPIResponse,
  crLoadRes: CandyRackPopupLoadResult,
): boolean {
  return (
    appFunctions.isBaseApp() &&
    appFunctions.isCandyRackExistingAndNotLoading(res) &&
    crLoadRes.isEnabled
  );
}

export function shouldLoadCandyCart(res: AppsInfoAPIResponse): boolean {
  const isCheckoutButtonOffersExistingInCandyRack: boolean =
    res.candyrack &&
    res.candyrack.is_installed &&
    res.candyrack.is_checkout_button_offers_existing;

  return (
    ((appFunctions.isBaseApp() &&
      (isCheckoutButtonOffersExistingInCandyRack ||
        isMultipleDiscountsExist(REGISTERED_APPS.CANDYRACK))) ||
      (appFunctions.isForkedApp() &&
        res.candycart.is_installed &&
        !isCheckoutButtonOffersExistingInCandyRack)) &&
    !window.CandyCart?.isLoading &&
    !isCheckoutPage()
  );
}

export function shouldLoadLastUpsell(res: AppsInfoAPIResponse): boolean {
  const isThankYouPageOffersExistingInCandyRack: boolean =
    res.candyrack &&
    res.candyrack.is_installed &&
    res.candyrack.is_thank_you_page_offers_existing;

  return (
    ((appFunctions.isBaseApp() && isThankYouPageOffersExistingInCandyRack) ||
      (res.last_upsell?.is_installed &&
        appFunctions.isForkedApp() &&
        !isThankYouPageOffersExistingInCandyRack)) &&
    isCheckoutPage() &&
    !window.LastUpsell?.isLoading
  );
}

/**
 * @description CR needs to be loaded on cart page to get discount info
 * TODO: APPS-5638 Add tests (technical debt)
 */
export const canLoadCandyRack = (): CandyRackPopupLoadResult => {
  if (
    window.CANDYRACK_DISABLE_CANDYRACK_LOAD &&
    window.CANDYRACK_DISABLE_CANDYRACK_LOAD()
  ) {
    logInfo('Blocking Candy Rack popup loading', REGISTERED_APPS.CANDYRACK);
    return { isEnabled: false, shouldRender: false };
  }
  if (
    canLoadOnUrl(AppsPageType.CANDY_RACK_PRODUCTS) ||
    canLoadOnUrl(AppsPageType.CANDY_RACK_COLLECTIONS) ||
    getCheckoutButtonSelectors().length > 0 ||
    window.CANDYRACK_FORCE_LOAD
  ) {
    return {
      isEnabled: true,
      shouldRender: true,
    };
  } else if (window.location.pathname.includes('cart')) {
    // On cart page check for storage items
    const discountedProduct = storage.getItem(
      CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY,
    );
    const associatedProducts = storage.getItem(
      CANDYRACK_ASSOCIATED_PRODUCTS_LOCAL_STORAGE_KEY,
    );
    const discountCode = storage.getItem(
      CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY,
    );
    // CR needs to be loaded in all these scenarios
    return associatedProducts || discountCode || discountedProduct
      ? { isEnabled: true, shouldRender: false }
      : { isEnabled: false, shouldRender: false };
  }
  return { isEnabled: false, shouldRender: false };
};
