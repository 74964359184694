enum REGISTERED_APPS {
  CANDYCART = 'candycart',
  CANDYRACK = 'candyrack',
  LAST_UPSELL = 'last_upsell',
}

const READABLE_APP_MAP: Record<REGISTERED_APPS, string> = {
  candycart: 'Candy Cart',
  candyrack: 'Candy Rack',
  last_upsell: 'Last Upsell',
};

export interface SupportedFeatures extends Record<string, boolean> {
  embedBlock: boolean;
  PPE_ADMIN: boolean;
}

export { REGISTERED_APPS, READABLE_APP_MAP };
