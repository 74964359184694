/**
 * @description Candy Rack specific requests
 */

//@ts-ignore
import { OffersResponseBody, Product } from '../types';
import getLanguage from '../utils/getLanguage';
import { parseJson, parseJsonDeprecated, stringifyUrlParams } from './utils';

async function getOffers(
  product: Product,
  productVariantId?: number,
): Promise<OffersResponseBody> {
  const productVariantIdQuery = productVariantId || '';
  const lang = getLanguage() || '';
  const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
  const response = await parseJson(
    fetch(
      `${hostname}api/candyrack/offers?shop=${window.Shopify.shop}&product=${product.id}&product_variant=${productVariantIdQuery}&lang=${lang}`,
    ),
  );
  return response as OffersResponseBody;
}

function productsCoveredByDiscount(
  discountCodes: string[],
  productIds: number[],
  variantIds: number[],
) {
  const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
  const obj = {
    shop: window.Shopify.shop,
    discount_codes: discountCodes,
    product_ids: productIds,
    variant_ids: variantIds,
  };

  const query = stringifyUrlParams(obj);

  return parseJsonDeprecated(
    fetch(`${hostname}api/candyrack/covered-by-discount?${query}`),
  );
}

async function generateDiscountCode(
  myshopifyDomain: string,
  offerId: number,
  productId: number,
  quantity: number | undefined,
) {
  const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
  const response = await parseJsonDeprecated(
    fetch(`${hostname}api/candyrack/generate-discount`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        shop: myshopifyDomain,
        entitled_product_id: productId,
        offer: offerId,
        quantity: quantity,
      }),
    }),
  );

  return response.discount_code;
}

async function productHandleForVariant(variantId?: number) {
  if (!variantId) {
    return;
  }
  const hostname = process.env.REACT_APP_CUSTOMER_API_HOSTNAME;
  const obj = {
    shop: window.Shopify.shop,
    variant_id: variantId,
  };

  const query = stringifyUrlParams(obj);

  const response = await parseJsonDeprecated(
    fetch(`${hostname}api/candyrack/product-handle-for-variant?${query}`),
  );
  return response.handle;
}

export {
  getOffers,
  productsCoveredByDiscount,
  generateDiscountCode,
  productHandleForVariant,
};
