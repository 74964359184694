import { Offer } from '../types';
import { customSessionStorage } from '../shared/utils/storage';

/*
 * In case an offer is added to the cart, we fill the shopifyProductHandleComputedInFE
 * as well as the shopifyProductVariantIdComputedInFE. However, for services the handle remains
 * empty. In this case the fallback to the relevant item_id takes place, when we need to send handle to the BE.
 */
export function getHandleForProductOrItemIdForService(offer: Offer) {
  let handleData = offer.shopifyProductHandleComputedInFE;
  if (!handleData) {
    // in case of service we use the item_id
    const foundItem = offer.items.find((item) =>
      item.item_variants.some(
        (itemVariant) =>
          itemVariant === offer.shopifyProductVariantIdComputedInFE,
      ),
    );
    if (foundItem) {
      handleData = foundItem.item_id.toString();
    }
  }
  return handleData;
}

export function chooseOfferForABTest(abTestId: number, offers: Offer[]) {
  /* In case AB Test is active (= abTestId is not null), backend sends in OfferResponse only offers that are in ABTest
   *  and frontend chooses one of them randomly. The choice is saved in sessionStorage to keep the same offer
   * for the whole customer session.
   *  */
  const storageKey = 'candyrack-ab-tests';
  let selectedOffer: Offer;
  let abTestsChoicesString = customSessionStorage.getItem(storageKey);
  let abTestsChoices = abTestsChoicesString
    ? JSON.parse(abTestsChoicesString)
    : {};
  if (abTestId in abTestsChoices) {
    const selectedOfferId = abTestsChoices[abTestId];
    const selectedOfferIndex = offers.findIndex(
      (offer) => offer.id === selectedOfferId,
    );
    if (selectedOfferIndex !== -1) {
      return offers[selectedOfferIndex];
    }
  }
  const selectedOfferIndex = Math.floor(Math.random() * offers.length);
  selectedOffer = offers[selectedOfferIndex];
  abTestsChoices[abTestId] = selectedOffer.id;
  customSessionStorage.setItem(storageKey, JSON.stringify(abTestsChoices));
  return selectedOffer;
}
