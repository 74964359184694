/**
 * @description util functions used in clients
 */

import { isAPIError } from './types';

const ajaxApiRequestConfig: RequestInit = {
  method: 'POST',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

function stringifyUrlParams(obj: any) {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, String(v)));
    } else {
      params.append(key, String(value));
    }
  }
  return params.toString();
}

/*
 ** Technical debt:
 ** I would prefer throwing an error for any response that is not ok.
 ** Unfortunately, it requires a bigger refactoring, as we previously
 ** were only returning null for 404, and returning the response as is
 ** for everything when not >= 500 && !== 404
 ** It also requires full QA round :(
 */
async function parseJsonDeprecated(
  fetchPromise: Promise<any>,
  refetchPromise: Promise<any> | null = null,
) {
  const response = await fetchPromise;
  if (response.status >= 500) {
    throw Error('Something unexpected happened.');
  }
  if (response.status === 404) {
    if (refetchPromise) {
      const refetchedResponse = await refetchPromise;
      if (refetchedResponse.status === 404) {
        return null;
      }
      return await refetchedResponse.json();
    }
    return null;
  }
  return await response.json();
}

async function parseJson(
  fetchPromise: Promise<any>,
  is404handled: boolean = false,
) {
  const response = await fetchPromise;

  if (!response?.ok) {
    // Used for situations where we dont want to crash the application - e.g getProduct()
    if (response.status === 404 && is404handled) {
      return null;
    }
    throw new Error(
      `Request failed with status ${response.status}: ${response.statusText}`,
    );
  }
  return await response.json();
}

/*
 ** Technical debt:
 ** We sometimes wrap the fetchJson into parseJson directly,
 ** other times we wrap it inside the handleResponse.
 ** We need to find the intend of this and homogenise our approach
 */
async function handleResponseDeprecated(fetchPromise: Promise<any>) {
  const response = await fetchPromise;
  let data = null;
  let parseError = false;
  try {
    data = await response.json();
  } catch (e) {
    parseError = true;
  }
  if (response.status >= 400) {
    if (!isAPIError(data) || parseError) {
      return { error_code: "It's not you, it's us!", errors: [] };
    }
  }
  return data;
}

function getCurrentLocale(path: string) {
  if (window.CANDYRACK_DISABLE_LOCALE) {
    return '';
  }
  // For TY page
  if (path.includes('checkout')) {
    const split = path.split('/');
    const locale = split[split.length - 2];
    return locale.split('-')[0].length === 2 ? locale : '';
  }
  const matches = /^\/([a-z]{2}(?:-[a-z]{2})?)(?:\/|$)/gi.exec(path);
  return matches && matches[0] ? `${matches[1]}` : '';
}

function getCurrentLocalePrefix(path: string) {
  const currentLocale = getCurrentLocale(path);
  return currentLocale ? `/${currentLocale}` : '';
}

async function timeoutFetch(url: string, timeout: number) {
  return new Promise((resolve, reject) => {
    fetch(url).then(resolve, reject);

    if (timeout) {
      const e = new Error(
        `Response exceeded ${timeout / 1000} second threshold`,
      );
      setTimeout(reject, timeout, e);
    }
  });
}

export {
  ajaxApiRequestConfig,
  stringifyUrlParams,
  parseJson,
  parseJsonDeprecated,
  handleResponseDeprecated,
  getCurrentLocalePrefix,
  getCurrentLocale,
  timeoutFetch,
};
