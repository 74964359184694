import { AppsPageType } from '../types';
import { CandyRackPopupLoadResult } from './app-utils';

export function isRelevantPageForTracking(
  crLoadRes: CandyRackPopupLoadResult,
): boolean {
  return crLoadRes.isEnabled || isCheckoutPage();
}

export function isCheckoutPage(): boolean {
  const currentUrl = window.location.pathname;
  const matches = currentUrl.match(/[0-9]+\/orders|checkouts\/[A-Za-z0-9]+/);
  return matches?.length ? true : false;
}

export function canLoadOnUrl(type: AppsPageType): boolean {
  const currentUrl = window.location.pathname;
  return currentUrl.includes(type);
}
