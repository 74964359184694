export interface CandyRackText {
  title: string;
  subtitle: string;
  add: string;
  added: string;
  upgrade: string;
  upgraded: string;
  back: string;
  cart_discount_note: string;
  reject: string;
  continue_to: string;
}

export interface CandyCartText {
  title: string;
  subtitle: string;
  add: string;
  added: string;
  back: string;
  continue_to: string;
  headline: string;
}

export interface LastUpsellText {
  title: string;
  order_name: string;
  order_subheadline: string;
  add: string;
  added: string;
  back: string;
  continue_to: string;
  see_offers: string;
  subtitle: string;
}

interface SettingsButton {
  colors: {
    background: string;
    text: string;
    border: string;
  };
  border: {
    enabled: boolean;
    width: string; // TODO ensure BE updated its impl
  };
}

/*
 * The difference between 'keep_popup_open' and 'stay_at_product_page' is primarily based on the
 * context and user interaction point where these actions are applied.
 *
 * 'keep_popup_open':
 * - Purpose: Used during the add-to-cart operation to ensure that additional action can be triggered
 *   after the item is added to the cart.
 * - Behavior: Keeps the current popup (modal) open, allowing the user to continue selecting items or
 *   perform further actions without interruption.
 *
 * 'stay_at_product_page':
 * - Purpose: Indicates that after a specific user action, such as closing the popup (e.g., by
 *   clicking on the "Continue" button), the user should remain on the current product page.
 * - Behavior: Ensures that there is no navigation away from the current page after the popup is
 *   closed.
 */
type GeneralButtonAction =
  | 'redirect_to_cart'
  | 'redirect_to_checkout'
  | 'keep_popup_open'
  | 'theme_settings'
  | 'stay_at_product_page';

/*
 * 'theme_settings':
 * - A click on the ATC button is triggered and allows the native flow.
 * - In case of redirects we need to add the parent using the add.js request.
 */
export type AddButtonAction =
  | 'redirect_to_cart'
  | 'redirect_to_checkout'
  | 'keep_popup_open'
  | 'theme_settings';

interface ActionableButton<T extends GeneralButtonAction> {
  action: T;
}

interface CandyRackButtons {
  close: ActionableButton<ButtonAction>;
  add: SettingsButton & ActionableButton<AddButtonAction>;
  continue_to: SettingsButton & ActionableButton<ButtonAction>;
  reject: SettingsButton & ActionableButton<ButtonAction>;
}

interface CandyCartButtons {
  add: SettingsButton;
  continue_to: SettingsButton;
}

interface LastUpsellButtons {
  add: SettingsButton;
  continue_to: SettingsButton;
  see_offers: { colors: { background: string; text: string } };
}

export type PopupLayoutType = 'tree' | 'grid';

export type ImageSize = 'small' | 'medium' | 'large';

export type CandyRackSettings = {
  text: CandyRackText;
  buttons: CandyRackButtons;
  custom_css: string;
  max_popup_session_views: number;
  is_subheadline_enabled: boolean;
  is_reject_enabled: boolean;
  image_size: ImageSize;
  popup_layout: PopupLayoutType;
};

export type LastUpsellSettings = {
  text: LastUpsellText;
  buttons: LastUpsellButtons;
  custom_css: string;
  time_to_pay_upsells: number | null;
  image_size?: ImageSize;
  popup_layout?: PopupLayoutType;
  is_subheadline_enabled: boolean;
};

export type CandyCartSettings = {
  text: CandyCartText;
  buttons: CandyCartButtons;
  ajax_cart_watcher_selector: string;
  custom_css: string;
  custom_cart_icon: string;
  is_subheadline_enabled: boolean;
  image_size?: ImageSize;
};

export type Settings =
  | CandyRackSettings
  | LastUpsellSettings
  | CandyCartSettings;

export type ButtonAction = Exclude<GeneralButtonAction, 'keep_popup_open'>;

export const isCandyRackSettings = (
  varToBeChecked: any,
): varToBeChecked is CandyRackSettings =>
  varToBeChecked.buttons?.reject !== undefined;

export const isCandyCartSettings = (
  varToBeChecked: any,
): varToBeChecked is CandyCartSettings =>
  varToBeChecked['ajax_cart_watcher_selector'] !== undefined;

export const isLastUpsellSettings = (
  varToBeChecked: any,
): varToBeChecked is LastUpsellSettings =>
  varToBeChecked['button_color'] !== undefined;

export const isOfType = <T>(
  varToBeChecked: any,
  propertyToCheckFor: keyof T,
): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

/*
 * CandyRack is using the LastUpsellSettings for the Thank You Page. We want to enable GridLayout also there.
 */
export const isGridLayout = (settings: Settings): boolean =>
  !isCandyCartSettings(settings) && settings.popup_layout === 'grid';

export const popupLayoutCssPostfix = (settings: Settings): string =>
  isGridLayout(settings) ? '-grid' : '';
