export interface APIError {
  /*eslint no-throw-literal: "off"*/
  error_code: string;
  errors: any;
}

export interface NetworkError {
  message: string;
  name: string;
}

abstract class Transformable {
  public static transform<T1, T2>(from: T1): T2 {
    throw 'Not Implemented';
  }
}

const ERR_MAP = new Map<string, string>([
  [
    'order_cannot_be_edited',
    'We couldn’t add the item to the order. Maybe the order has been fulfilled already? Please contact us for more details.',
  ],
  [
    'invalid_input_data',
    'We couldn’t add the item to the order. Please refresh the page and try it again.',
  ],
  [
    'variant_id_not_unique',
    'We couldn’t add the item to the order. Please contact us for more details.',
  ],
  [
    'offer_does_not_exist',
    'We couldn’t add the item to the order as the offer is not valid anymore. Please contact us for more details.',
  ],
]);

class APPError implements Transformable {
  errorCode: string;
  errors: string[] | undefined;
  message: any;

  transpile(errorCode: string) {
    return ERR_MAP.get(errorCode) || errorCode;
  }

  constructor(error: NetworkError | APIError) {
    if (isNetworkError(error)) {
      this.errorCode = error.name;
      this.message = error.message;
    } else {
      this.errorCode = error.error_code;
      this.errors = error.errors;
      this.message = this.transpile(error.error_code);
    }
  }

  public static transform(error: NetworkError | APIError) {
    return new APPError(error);
  }
}

const isAPIError = (varToBeChecked: any): varToBeChecked is APIError =>
  varToBeChecked?.error_code !== undefined;

const isNetworkError = (error: any): error is NetworkError =>
  error?.message !== undefined;

export interface EditOrderAPIResponse {
  redirect_url: string;
}

export { isAPIError, APPError, isNetworkError };

export type RecommendedProductsRequestConfig = {
  hostname: string | undefined;
  appPrefix: 'candyrack' | 'candycart' | 'last-upsell';
};

export enum IGNORE_TAGS {
  CR_IGNORE = 'cr-ignore',
  LU_IGNORE = 'lu-ignore',
  CC_IGNORE = 'cc-ignore',
}
